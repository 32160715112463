/** @format */

.footer_area {
  background: var(--text-primary-Color, #101828);
}

.footer_logo {
  margin-bottom: 15px;
}
  footer {background:url('../../Helper/images/new/footer_bg.png') no-repeat;width:100%;position: relative; padding: 20px 0;background-size: 100% 100%;}
footer .container{position: relative;}
/* footer:before{background:rgba(3, 2, 27, 0.8);position: absolute;top:0;left:0;right:0;bottom:0;content:"";} */
.footer_inner{ display: flex;flex-wrap: wrap;justify-content: space-between;}
.footer-section { flex: 1; min-width: 300px; margin: 20px 0;}
.footer-section .footerm_hd {margin-bottom: 15px;font-size: 18px;color:rgba(255, 255, 255, 1);font-weight: 700;}
.footer-section ul {list-style: none;}
.footer-section ul li {margin-bottom: 15px;}
.footer-section ul li a {color: rgba(255, 255, 255, 0.8);text-decoration: none;}
.footer-section p.para{font-size: 14px;line-height: 26px;color:rgba(255, 255, 255, 0.8);margin-bottom: 15px;}
.footer-section a {font-size: 14px;line-height: 20px; transition: letter-spacing 0.3s ease;}
.footer-section a:hover{letter-spacing: 0.7px;}
.footer-bottom {text-align: center;padding: 10px 0;margin-top: 20px;}
.footer-bottom p { font-size: 14px;font-weight: 400;line-height: 16.94px;text-align: left;color:rgba(255, 255, 255, 0.8);}
.footer_logo {margin-bottom: 15px;}
.social-icons {display: flex;gap: 10px;}
.social-icons a{width:35px;height:35px;background:#fff;border-radius: 100%;align-items: center;justify-content: center;display: flex;color:#03021B}
.social-icons a svg{transition: transform 1s;}
.social-icons a:hover{background:rgba(229, 43, 32, 1);color: #FFF;}
.social-icons a:hover svg{transform: rotate(1turn);}
.footer-section2{padding-left:80px;}
.footer-section3{padding-left:10px;}
.footer-section4{padding-left: 15px;}
.contact-item {display: flex;align-items: flex-start;margin-bottom: 15px;flex-direction: column;gap:5px;
}
.contact-item i { font-size: 18px;margin-right: 10px;color: #fff;}
.contact-item span{display: flex;font-size: 16px;font-weight: 500;line-height: 19.36px;color:rgba(255, 255, 255, 1)}
.contact-item p {margin: 0 0 0 30px; font-size: 14px;font-weight: 400;line-height: 22px;color:rgba(255, 255, 255, 0.8)}
.contact-item a{color:#fff;text-decoration:none}
.contact-item a:hover { text-decoration: underline;}
@media (max-width: 1024px) {
  .footer-section{min-width: 200px;}
  .footer-section2{padding-left: 40px;}
  .footer-bottom{margin:0}
}
@media (max-width: 991.2px) {
  .footer-section4{padding-left: 0;}
}
@media (max-width: 768px) {
footer .container { flex-direction: column;align-items: center;}
}
@media (max-width: 568px) {
  .footer-section{margin:20px 0 0}
  .footer-section3{padding-left: 0;}
  .footer-section .footerm_hd{font-size: 16px;}
  
}
@media (max-width: 440px) {
  .footer-section2{padding-left: 0;}
}
