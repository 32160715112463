/* cyrillic-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_aaf875';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_aaf875 {font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875';font-style: normal
}

:root {
  --primary-Color: #005b99;
  --text-primary-Color: #101828;
  --text-secondary-Color: #121330;
  --white-text-color: #fff;
  --neutral-800: #170F49;
  --neutral-600: #6F6C90;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-Color: #005b99;
  }
}

body {
  background-color: #fff;
  color: var(--text-primary-Color, #101828);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:after,
:before {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.demo-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  overflow: hidden;
}

.demo-close-btn {
  position: absolute !important;
  right: 19px;
  top: 5px;
  z-index: 1;
}

.demo-popup {
  min-width: 1000px;
  height: 650px;
}
@media only screen and (max-width:1024px) {
  .common_section,.product_homlist.padd-in{padding:30px 0 40px!important}
}
@media only screen and (max-width:568px) {
  .container {
    padding: 0 20px;
  }

  .demo-popup {
    min-width: 385px;
  }
  .demo-close-btn {
    right: -10px;
    top: -49px;
  }

  .demo-close-btn > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: #fff;
  }
}
.com_hd {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: rgba(34, 34, 34, 1);
}

/*Responsive*/
@media (max-width: 1024px) {
  .clientsinner{gap: 20px;flex-wrap: wrap;justify-content: center;}
  .common_section,.product_homlist.padd-in{padding:30px 0 40px}
  
}
@media only screen and (max-width:768px) {
  .com_desc br{display: none;}
}
@media only screen and (max-width:480px) {
  .com_hd{font-size: 20px;text-align: center;line-height: 30px;}
   .com_desc{font-size: 14px;}
   .common_headingcontent{gap:10px}
   
   
  }

/** @format */

.footer_area {
  background: var(--text-primary-Color, #101828);
}

.footer_logo {
  margin-bottom: 15px;
}
  footer {background:url(/_next/static/media/footer_bg.dfc03886.png) no-repeat;width:100%;position: relative; padding: 20px 0;background-size: 100% 100%;}
footer .container{position: relative;}
/* footer:before{background:rgba(3, 2, 27, 0.8);position: absolute;top:0;left:0;right:0;bottom:0;content:"";} */
.footer_inner{ display: flex;flex-wrap: wrap;justify-content: space-between;}
.footer-section { flex: 1 1; min-width: 300px; margin: 20px 0;}
.footer-section .footerm_hd {margin-bottom: 15px;font-size: 18px;color:rgba(255, 255, 255, 1);font-weight: 700;}
.footer-section ul {list-style: none;}
.footer-section ul li {margin-bottom: 15px;}
.footer-section ul li a {color: rgba(255, 255, 255, 0.8);text-decoration: none;}
.footer-section p.para{font-size: 14px;line-height: 26px;color:rgba(255, 255, 255, 0.8);margin-bottom: 15px;}
.footer-section a {font-size: 14px;line-height: 20px; transition: letter-spacing 0.3s ease;}
.footer-section a:hover{letter-spacing: 0.7px;}
.footer-bottom {text-align: center;padding: 10px 0;margin-top: 20px;}
.footer-bottom p { font-size: 14px;font-weight: 400;line-height: 16.94px;text-align: left;color:rgba(255, 255, 255, 0.8);}
.footer_logo {margin-bottom: 15px;}
.social-icons {display: flex;gap: 10px;}
.social-icons a{width:35px;height:35px;background:#fff;border-radius: 100%;align-items: center;justify-content: center;display: flex;color:#03021B}
.social-icons a svg{transition: transform 1s;}
.social-icons a:hover{background:rgba(229, 43, 32, 1);color: #FFF;}
.social-icons a:hover svg{transform: rotate(1turn);}
.footer-section2{padding-left:80px;}
.footer-section3{padding-left:10px;}
.footer-section4{padding-left: 15px;}
.contact-item {display: flex;align-items: flex-start;margin-bottom: 15px;flex-direction: column;gap:5px;
}
.contact-item i { font-size: 18px;margin-right: 10px;color: #fff;}
.contact-item span{display: flex;font-size: 16px;font-weight: 500;line-height: 19.36px;color:rgba(255, 255, 255, 1)}
.contact-item p {margin: 0 0 0 30px; font-size: 14px;font-weight: 400;line-height: 22px;color:rgba(255, 255, 255, 0.8)}
.contact-item a{color:#fff;text-decoration:none}
.contact-item a:hover { text-decoration: underline;}
@media (max-width: 1024px) {
  .footer-section{min-width: 200px;}
  .footer-section2{padding-left: 40px;}
  .footer-bottom{margin:0}
}
@media (max-width: 991.2px) {
  .footer-section4{padding-left: 0;}
}
@media (max-width: 768px) {
footer .container { flex-direction: column;align-items: center;}
}
@media (max-width: 568px) {
  .footer-section{margin:20px 0 0}
  .footer-section3{padding-left: 0;}
  .footer-section .footerm_hd{font-size: 16px;}
  
}
@media (max-width: 440px) {
  .footer-section2{padding-left: 0;}
}

.nav_area {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
  transition: background-color 0.4s ease, box-shadow 0.5s ease;background:#FFF;
}

.nav_area_home {
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  box-shadow: #000000;
}

.nav_area_common {
  background-color: #fff;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;
}

.nav_container_right .nav_container_right_inner,
.nav_container_left,
.nav_container_right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.nav_container_right {
  gap: 30px;
}
.nav_container_right_innermenu a,.dropdownmenu .menu-label,.navbar_desktop .mainspan {
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  font-size: 17px;
  position: relative;
  /* padding-bottom: 5px; */
}
.nav_container_right_innerbtn a.cta{padding: 10px 20px;border: 2px solid #005b99;background: none;color: #005b99;cursor: pointer;border-radius: 25px;position: relative;overflow: hidden;font-size: 16px;display: flex;gap:5px}
.nav_container_right_innerbtn a.cta:hover:before{display: none;}
.nav_container_right_innerbtn a.cta:after {position: absolute;content: " ";top: 0;left: 0;z-index: -1; width: 100%; height: 100%;
    transition: all 0.3s ease;transform: scale(.1);border-radius: 23px;}
.nav_container_right_innerbtn a.cta:hover {color: #fff;}
.nav_container_right_innerbtn a.cta:hover:after {background: #005b99; transform: scale(1);}
.withsubmenunav {
  position: relative;
  cursor: pointer;
}
.withsubmenunav .mainspan svg{position: absolute;right:0}
.withsubmenunav .mainspan.currentPage svg,.navbar_mobile .mainspan.showSubmenu svg {transform: rotate(180deg);}


.nav_subulweb {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 50px;
  list-style: none;
  background-color: #fff;
  min-width: 180px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px #0000001a;
  transition: 0.8s;
  padding: 20px;
  list-style: none;
}
.withsubmenunav:hover .nav_subulweb {
  opacity: 1;
  top: 35px;
  visibility: visible;
}
.mobile_subnav {
  list-style: none;
  padding: 20px;
}
.nav_subul li {
  padding: 10px 0 10px 25px
}
.nav_subul li a {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
}
.withsubmenunav .mainspan {
  position: relative;
  padding-right: 23px;
}
.hidesubmenu {
  display: none;
}
.nav_subul li.header-text {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}
.dropdownmenu {
  position: relative;
  display: inline-block;line-height: 50px;
}

.dropdown-menucontent {
  display: none;
  position: absolute;
  background-color: #fff;top:48px;
  min-width: 270px;border-radius: 9px;padding:10px 0;
  box-shadow: 1px 4px 20px 0px #0000001A;


  z-index: 1;
}
.menu-label {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;height:20px;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown-menucontent a {
  color:#777777;
  padding: 7px 16px 7px 40px;font-size: 15px;font-weight: 500;line-height: 19px;
  text-decoration: none;
  display: block;position: relative;
}
.dropdown-menucontent a:hover{background:#005b99;color:#FFF}
.nav_subulweb.ResourcesDropdown li a:before{width:18px;height:18px;position: absolute;left:-28px;top:0px;background: url(/_next/static/media/css_spritesmenu.4d43fd43.png) no-repeat;content:"";}
.nav_subulweb.ResourcesDropdown li:first-child a:before{background-position: -49px -10px;}
/* .nav_subulweb.ResourcesDropdown li:first-child a:hover:before{background-position: -10px -10px;} */
.nav_subulweb.ResourcesDropdown li:nth-child(2) a:before{background: url(/_next/static/media/css_spritesmenu2.1ca1ab06.png) no-repeat;background-position: -48px -10px;}
/* .nav_subulweb.ResourcesDropdown li:nth-child(2) a:hover:before{background-position: -10px -10px;} */
.nav_subulweb.ResourcesDropdown li:nth-child(3) a:before{background: url(/_next/static/media/css_spritesmenu3.f58b249a.png) no-repeat;background-position: -46px -10px;}
/* .nav_subulweb.ResourcesDropdown li:nth-child(3) a:hover:before{background-position: -10px -10px;} */

.dropdownmenu .dropdown-menucontent {
  display: block;
}
.ResourcesDropdown {
  display: flex;
  gap: 30px;
}
.currentPage {
  color: var(--primary-Color, #005b99);
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-Color, #005b99);
}

/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 35px;
  right:25px;
  top: 10px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */

/* .bm-cross {
    background: #bdc3c7;
} */

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
}

/* General sidebar styles */

.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding: 26px 5px;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */

.bm-item {
  display: inline-block;
  color: #000;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 18px;
  font-weight: 500;
}

.bm-burger-button .bm-burger-bars,
.bm-cross-button .bm-cross,
.bm-burger-button {
  display: none;
}

.navbar_btn {
  display: flex;
  gap: 20px;
}

.nav_area.onscroll_bg {
  background-color: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.nav_area_blog {
  box-shadow: 0 0 1px 0px rgb(0 0 0 / 50%);
}

@media only screen and (max-width: 925px) {
  .bm-burger-button {
    display: block;
  }

  .navbar_desktop,
  .navbar_desktop .navbar_btn,.nav_container_right .nav_container_right_inner.nav_container_right_innerbtn,.dropdownmenu {
    display: none;
  }

  .navbar_mobile {
    margin-bottom: 20px;
  }
  .mobile_subnav .navbar_mobile {
    margin-bottom: 0;
  }

  .navbar_btn {
    flex-direction: column;
  }

  .nav_container {
    padding: 13px 40px 13px 0;
  }

  .nav_logo {
    height: 24px;
    width: auto;
  }

  .mobile_nav_btn {
    padding: 5px 10px;
  }

  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 22px;
  }
}
@media only screen and (max-width: 768px) {
header{padding:5px 0}
.nav_area{min-height:60px}
.nav_logo{height:30px}
}
@media only screen and (max-width: 367px) {
  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 17px;
  }
  .nav_container {
    padding: 13px 40px 13px 0;
  }
}

