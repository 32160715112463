.nav_area {
  position: sticky;
  top: 0;
  z-index: 9;
  transition: background-color 0.4s ease, box-shadow 0.5s ease;background:#FFF;
}

.nav_area_home {
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  box-shadow: #000000;
}

.nav_area_common {
  background-color: #fff;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;
}

.nav_container_right .nav_container_right_inner,
.nav_container_left,
.nav_container_right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.nav_container_right {
  gap: 30px;
}
.nav_container_right_innermenu a,.dropdownmenu .menu-label,.navbar_desktop .mainspan {
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  font-size: 17px;
  position: relative;
  /* padding-bottom: 5px; */
}
.nav_container_right_innerbtn a.cta{padding: 10px 20px;border: 2px solid #005b99;background: none;color: #005b99;cursor: pointer;border-radius: 25px;position: relative;overflow: hidden;font-size: 16px;display: flex;gap:5px}
.nav_container_right_innerbtn a.cta:hover:before{display: none;}
.nav_container_right_innerbtn a.cta:after {position: absolute;content: " ";top: 0;left: 0;z-index: -1; width: 100%; height: 100%;
    transition: all 0.3s ease;-webkit-transform: scale(.1);transform: scale(.1);border-radius: 23px;}
.nav_container_right_innerbtn a.cta:hover {color: #fff;}
.nav_container_right_innerbtn a.cta:hover:after {background: #005b99;-webkit-transform: scale(1); transform: scale(1);}
.withsubmenunav {
  position: relative;
  cursor: pointer;
}
.withsubmenunav .mainspan svg{position: absolute;right:0}
.withsubmenunav .mainspan.currentPage svg,.navbar_mobile .mainspan.showSubmenu svg {transform: rotate(180deg);}


.nav_subulweb {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 50px;
  list-style: none;
  background-color: #fff;
  min-width: 180px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px #0000001a;
  transition: 0.8s;
  padding: 20px;
  list-style: none;
}
.withsubmenunav:hover .nav_subulweb {
  opacity: 1;
  top: 35px;
  visibility: visible;
}
.mobile_subnav {
  list-style: none;
  padding: 20px;
}
.nav_subul li {
  padding: 10px 0 10px 25px
}
.nav_subul li a {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
}
.withsubmenunav .mainspan {
  position: relative;
  padding-right: 23px;
}
.hidesubmenu {
  display: none;
}
.nav_subul li.header-text {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}
.dropdownmenu {
  position: relative;
  display: inline-block;line-height: 50px;
}

.dropdown-menucontent {
  display: none;
  position: absolute;
  background-color: #fff;top:48px;
  min-width: 270px;border-radius: 9px;padding:10px 0;
  box-shadow: 1px 4px 20px 0px #0000001A;


  z-index: 1;
}
.menu-label {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;height:20px;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown-menucontent a {
  color:#777777;
  padding: 7px 16px 7px 40px;font-size: 15px;font-weight: 500;line-height: 19px;
  text-decoration: none;
  display: block;position: relative;
}
.dropdown-menucontent a:hover{background:#005b99;color:#FFF}
.nav_subulweb.ResourcesDropdown li a:before{width:18px;height:18px;position: absolute;left:-28px;top:0px;background: url(../../Helper/images/new/css_spritesmenu.png) no-repeat;content:"";}
.nav_subulweb.ResourcesDropdown li:first-child a:before{background-position: -49px -10px;}
/* .nav_subulweb.ResourcesDropdown li:first-child a:hover:before{background-position: -10px -10px;} */
.nav_subulweb.ResourcesDropdown li:nth-child(2) a:before{background: url(../../Helper/images/new/css_spritesmenu2.png) no-repeat;background-position: -48px -10px;}
/* .nav_subulweb.ResourcesDropdown li:nth-child(2) a:hover:before{background-position: -10px -10px;} */
.nav_subulweb.ResourcesDropdown li:nth-child(3) a:before{background: url(../../Helper/images/new/css_spritesmenu3.png) no-repeat;background-position: -46px -10px;}
/* .nav_subulweb.ResourcesDropdown li:nth-child(3) a:hover:before{background-position: -10px -10px;} */

.dropdownmenu .dropdown-menucontent {
  display: block;
}
.ResourcesDropdown {
  display: flex;
  gap: 30px;
}
.currentPage {
  color: var(--primary-Color, #005b99);
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-Color, #005b99);
}

/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 35px;
  right:25px;
  top: 10px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */

/* .bm-cross {
    background: #bdc3c7;
} */

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
}

/* General sidebar styles */

.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding: 26px 5px;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */

.bm-item {
  display: inline-block;
  color: #000;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 18px;
  font-weight: 500;
}

.bm-burger-button .bm-burger-bars,
.bm-cross-button .bm-cross,
.bm-burger-button {
  display: none;
}

.navbar_btn {
  display: flex;
  gap: 20px;
}

.nav_area.onscroll_bg {
  background-color: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.nav_area_blog {
  box-shadow: 0 0 1px 0px rgb(0 0 0 / 50%);
}

@media only screen and (max-width: 925px) {
  .bm-burger-button {
    display: block;
  }

  .navbar_desktop,
  .navbar_desktop .navbar_btn,.nav_container_right .nav_container_right_inner.nav_container_right_innerbtn,.dropdownmenu {
    display: none;
  }

  .navbar_mobile {
    margin-bottom: 20px;
  }
  .mobile_subnav .navbar_mobile {
    margin-bottom: 0;
  }

  .navbar_btn {
    flex-direction: column;
  }

  .nav_container {
    padding: 13px 40px 13px 0;
  }

  .nav_logo {
    height: 24px;
    width: auto;
  }

  .mobile_nav_btn {
    padding: 5px 10px;
  }

  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 22px;
  }
}
@media only screen and (max-width: 768px) {
header{padding:5px 0}
.nav_area{min-height:60px}
.nav_logo{height:30px}
}
@media only screen and (max-width: 367px) {
  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 17px;
  }
  .nav_container {
    padding: 13px 40px 13px 0;
  }
}
